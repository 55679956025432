import * as $ from 'jquery';
import bulmaCollapsible from '@creativebulma/bulma-collapsible';

export default class Segment {
  constructor() {
    this.initPage();
    bulmaCollapsible.attach();
  }

  initPage() {
    let $bulkSegmentType = $('#bulk-segment-type');
    let $segmentType = $('#type');
    this.hideAllForms();
    this.hideAllBulkFields();
    $segmentType.change((e) => this.onSegmentTypeChange(e)).trigger('change');
    $('#manual-segment #manual_enabled').click(() =>
      $segmentType.trigger('change')
    );
    $('.bulk-upload-expand-button').click((e) =>
      this.onBulkUploadExpandButtonClick(e)
    );
    $('.bulk-upload-error-modal-open').click((e) =>
      this.onBulkUploadErrorModalButtonClick(e)
    );
    $('#locations_v1').change((_e) => {
      if ($('#locations_v1').val()) {
        $('#locations_v1').val('on');
      } else {
        $('#locations_v1').val(null);
      }
      this.populateColumnMapping(false);
      this.onBulkSegmentTypeChange($bulkSegmentType);
    });
    $('#bulk-upload-submit').click((e) => this.saveColumnMapping(e));
    $bulkSegmentType
      .change((_e) => {
        this.populateColumnMapping();
        this.onBulkSegmentTypeChange($bulkSegmentType);
      })
      .trigger('change');
    this.initMetadataButtons();
    this.attachSegmentListeners();
  }

  populateColumnMapping(setLocationsCheckbox = true) {
    let ignoreEls = ['_token', 'file', 'row-start', 'bulk-segment-type'];
    if (!setLocationsCheckbox) {
      ignoreEls.push('locations_v1');
    }
    const setElementFunc = this.setElement;
    const getParsedMappingValueFunc = this.getParsedMappingValue;
    const storageKey = this.getLocalStorageKey();
    const mappings = localStorage.getItem(storageKey);
    const parsedMappings = JSON.parse(mappings);
    $('#bulk-upload-form')
      .find(':input')
      .each(function () {
        const elementName = this.name;
        if (ignoreEls.includes(elementName)) {
          return;
        }

        // Handle select2 els
        if (elementName === 'travel_type') {
          $("[name='travel_type']").val(
            getParsedMappingValueFunc(
              parsedMappings,
              'travel_type',
              'Business Travel'
            )
          );
          return;
        }

        if (elementName === 'locale') {
          $("[name='locale']").val(
            getParsedMappingValueFunc(parsedMappings, 'locale', 'en')
          );
          return;
        }

        setElementFunc(
          this,
          getParsedMappingValueFunc(parsedMappings, elementName, '')
        );
      });
  }

  getParsedMappingValue(parsedMappings, key, def) {
    let val = def;
    if (parsedMappings) {
      val = parsedMappings[key] ?? val;
    }
    return val;
  }

  setElement(element, value) {
    if (element.type === 'checkbox') {
      $(element).prop('checked', value);
      return;
    }
    $(element).val(value ?? '');
  }

  hideAllForms() {
    this.toggleInputs($('.segment-forms > div'), false);
    this.toggleInputs($('#manual-segment > div'), false);
    $(`.single-segment-methodology-quickview-link`).hide();
    $('#global-inputs').hide();
  }

  onSegmentTypeChange(e) {
    this.hideAllForms();
    if (e.currentTarget.value) {
      this.toggleInputs($('#manual-segment .manual_switch'), true);
      if ($('#manual-segment #manual_enabled').is(':checked')) {
        this.toggleInputs($('#manual-segment .manual_inputs'), true);
      } else {
        this.toggleInputs($(`#${e.currentTarget.value}-segment-form`), true);
      }
      $(
        `#single-segment-methodology-quickview-link-${e.currentTarget.value}`
      ).show();
      $('#global-inputs').show();
    }
  }

  toggleInputs($el, show = true) {
    show
      ? $el.show().find(':input').removeAttr('disabled')
      : $el.hide().find(':input').prop('disabled', true);
  }

  attachSegmentListeners() {
    $('#create_waste_segment')
      .on('change', function () {
        $('table.food_waste_weights').toggle(this.value === 'in_percentage');
      })
      .trigger('change');
    // Compute total percentage
    let $inputPercentages = $('input.food_waste_percentage'),
      $totalPercentage = $('#td_total_percentage'),
      total = 0;
    $inputPercentages
      .on('input', function () {
        total = 0;
        $inputPercentages.each((index, el) => {
          total += parseFloat(el.value);
        });
        $totalPercentage
          .val(total)
          .toggleClass('is-primary', Math.round(total) === 100)
          .toggleClass('is-danger', Math.round(total) !== 100);
      })
      .trigger('input');
  }

  isUsingV1Locations() {
    return $('#locations_v1').is(':checked');
  }

  /******************************************************************************************************************/
  ///////////////////////// BULK UPLOAD ERRORS
  /******************************************************************************************************************/
  onBulkUploadErrorModalButtonClick(e) {
    const uri = `${window.location.origin}/account/calculate/${e.currentTarget.dataset.projectId}/bulk-uploads/${e.currentTarget.dataset.bulkUploadId}/errors`;

    $(`#bulk-upload-error-modal`).addClass('is-active');
    $('#bulk-upload-error-modal-body').load(
      uri,
      function (response, status, _xhr) {
        if (status == 'error') {
          var msg = 'There was an error. Please contact your account manager.';
          window.alert(msg);
        }
      }
    );
  }

  /******************************************************************************************************************/
  ///////////////////////// BULK UPLOADS
  /******************************************************************************************************************/

  onBulkSegmentTypeChange(e) {
    this.hideAllBulkFields();
    switch (e.val()) {
      case 'air':
        return this.showBulkAirFields();
      case 'car':
        return this.showBulkCarFields();
      case 'coach':
        return this.showBulkCoachFields();
      case 'ferry':
        return this.showBulkFerryFields();
      case 'hotel':
        return this.showBulkHotelFields();
      case 'rail':
        return this.showBulkRailFields();
      case 'generic':
        return this.showBulkGenericFields();
      case 'taxi':
        return this.showBulkTaxiFields();
      default:
        console.error('Unsupported bulk segment type', e.currentTarget.value);
    }
  }

  showBulkAirFields() {
    $(`#col-aircraft_type-field`).show();
    $(`#col-carrier-field`).show();
    $(`#col-class-field`).show();
    $(`#col-passengers-field`).show();
    $(`#col-flight_number-field`).show();
    this.showBulkLocationFields('air');
  }

  showBulkCarFields() {
    $(`#col-car_type-field`).show();
    $(`#col-distance-field`).show();
    $(`#col-days-field`).show();
    $(`#col-supplier-field`).show();
    $(`#distance-unit-field`).show();
    this.showBulkLocationFields('car');
  }

  showBulkHotelFields() {
    $(`#col-nights-field`).show();
    $(`#col-rooms-field`).show();
    this.showBulkLocationFields('hotel');
  }

  showBulkTaxiFields() {
    $(`#col-taxi_type-field`).show();
    $(`#col-distance-field`).show();
    $(`#col-passengers-field`).show();
    $(`#col-supplier-field`).show();
    $(`#distance-unit-field`).show();
    this.showBulkLocationFields('taxi');
  }

  showBulkFerryFields() {
    $(`#col-ferry_passenger_type-field`).show();
    $(`#col-distance-field`).show();
    $(`#col-passengers-field`).show();
    $(`#col-supplier-field`).show();
    $(`#distance-unit-field`).show();
    this.showBulkLocationFields('ferry');
  }

  showBulkCoachFields() {
    $(`#col-distance-field`).show();
    $(`#col-passengers-field`).show();
    $(`#col-supplier-field`).show();
    $(`#distance-unit-field`).show();
    this.showBulkLocationFields('coach');
  }

  showBulkRailFields() {
    $(`#col-distance-field`).show();
    $(`#col-rail_type-field`).show();
    $(`#col-class-field`).show();
    $(`#col-passengers-field`).show();
    $(`#col-supplier-field`).show();
    $(`#distance-unit-field`).show();
    this.showBulkLocationFields('rail');
  }

  showBulkGenericFields() {
    $(`#col-traveller_count-field`).show();
    $(`#col-distance-field`).show();
    $(`#distance-unit-field`).show();
    $(`#bulk-import-methodology-quickview-link-generic`).show();
    this.showBulkLocationFields('generic');
  }

  hideAllBulkFields() {
    $(`.bulk-import-methodology-quickview-link`).hide();
    $(`.bulk-fields`).children().hide();
  }

  saveColumnMapping(_e) {
    const form = $('#bulk-upload-form');
    const data = this.getFormData(form);
    const storageKey = this.getLocalStorageKey();

    localStorage.setItem(storageKey, JSON.stringify(data));
  }

  getLocalStorageKey() {
    const segmentType = $('#bulk-segment-type').val();
    return `bulk-upload-mappings-${window.location.href}-${segmentType}`;
  }

  getFormData(form, _e) {
    const unindexed_array = form.serializeArray();
    const indexed_array = {};

    $.map(unindexed_array, function (n, _i) {
      indexed_array[n['name']] = n['value'];
    });
    return indexed_array;
  }

  showBulkLocationFields(type) {
    let fnChangeHintToRequired = function () {
      return $(this).text().replace('Optional', 'Required');
    };
    $('.bulk-fields.locations :input')
      .removeAttr('required')
      .attr('disabled', true)
      .find('.hint')
      .text(function () {
        $(this).text().replace('Required', 'Optional');
      });
    if (this.isUsingV1Locations()) {
      // v1 locations
      $('#col-locations-field')
        .attr('required', true)
        .show()
        .find(':input')
        .removeAttr('disabled');
    } else {
      // v2 locations
      switch (type) {
        case 'air':
          $('#col-\\[origin\\]\\[iata\\]-field')
            .show()
            .find(':input')
            .removeAttr('disabled')
            .attr('required', true);
          $('#col-\\[destination\\]\\[iata\\]-field')
            .show()
            .find(':input')
            .removeAttr('disabled')
            .attr('required', true);
          $('#col-\\[origin\\]\\[iata\\]-field .hint').text(
            fnChangeHintToRequired
          );
          $('#col-\\[destination\\]\\[iata\\]-field .hint').text(
            fnChangeHintToRequired
          );
          break;
        case 'car':
        case 'coach':
        case 'ferry':
        case 'rail':
        case 'generic':
        case 'taxi':
          // Enable all "origin and destination" fields
          $('[id^="col-\\[origin\\]"]')
            .show()
            .find(':input')
            .removeAttr('disabled');
          $('[id^="col-\\[destination\\]"]')
            .show()
            .find(':input')
            .removeAttr('disabled');
          break;
        case 'hotel':
          // Enable all "location" fields for a single location
          $('[id^="col-\\[location\\]"]').removeAttr('disabled').show();
          break;
        default:
          console.error('Type not expected or handled yet');
      }
    }
  }

  // This is new!
  showAddressFields() {
    $(`#col-name-field`).show();
    $(`#col-address_line_1-field`).show();
    $(`#col-address_line_2-field`).show();
    $(`#col-city_code-field`).show();
    $(`#col-city_name-field`).show();
    $(`#col-country_code-field`).show();
    $(`#col-country_name-field`).show();
    $(`#col-region-field`).show();
    $(`#col-postal_code-field`).show();
    $(`#col-full_address-field`).show();
  }

  onBulkUploadExpandButtonClick(_e) {
    $('.bulk-upload-expand-button').addClass('is-hidden');
    $('.bulk-upload-detail').removeClass('is-hidden');
  }

  /******************************************************************************************************************/
  ///////////////////////// METADATA
  /******************************************************************************************************************/

  initMetadataButtons() {
    this.metadataRowCount = 0;
    this.initMetadataAddButton();
    this.initMetadataRemoveButtons();
  }

  initMetadataAddButton() {
    const initMetadataRemoveButtons = this.initMetadataRemoveButtons;
    $('#metadata-button').on('click', () => {
      this.addMetadataRow(initMetadataRemoveButtons);
    });
  }

  initMetadataRemoveButtons() {
    $('.metadata-remove-button').on('click', (elem) => {
      $(elem.target).parent().detach();
    });
  }

  addMetadataRow(initMetadataRemoveButtons) {
    const templateRow = $('.metadata-field-template').children()[0];
    $(templateRow).clone().appendTo('.metadata-field-container');
    $('.metadata-field-container input[name="metadata[XXX][key]"]').attr(
      'name',
      'metadata[' + this.metadataRowCount + '][key]'
    );
    $('.metadata-field-container input[name="metadata[XXX][value]"]').attr(
      'name',
      'metadata[' + this.metadataRowCount + '][value]'
    );
    initMetadataRemoveButtons();
    this.metadataRowCount++;
  }
}
