import _ from 'lodash';

export default class Report {
  constructor() {
    this.totalInputsOnPage = 0;
    this.initReportForms();
  }

  initReportForms() {
    $('.add-accept-grouping-button').click((e) => {
      const $reportControls = $(e.currentTarget).parent();
      const $exampleGroupingControls = $(
        $reportControls.find('.example-grouping-type-controls .field')[0]
      );
      const $groupingFilterContainer = $(
        $reportControls.find('.grouping-include-container')[0]
      );
      const $clonedControls = this.copyControls(
        $exampleGroupingControls,
        `groupings_accept[${this.totalInputsOnPage}]`
      );

      // Append filters
      $groupingFilterContainer.append($clonedControls);

      // Display title
      const $acceptGroupingsText = $(
        $reportControls.find('.accept-groupings-text')[0]
      );
      $acceptGroupingsText.removeClass('is-hidden');

      // Show the type dropdown if more than 1 option
      if ($groupingFilterContainer.children().length > 1) {
        const groupingIncludeType = $reportControls.find(
          '.grouping-include-type'
        );
        const $groupingIncludeType = $(groupingIncludeType[0]);
        $groupingIncludeType.removeClass('is-hidden');
      }

      this.toggleSubGroupsSwitch($reportControls);
      this.initSelect2Controls($clonedControls);
      this.incrementTotalInputsOnPage();
      this.initRemoveGroupingButtons();
    });

    $('.add-reject-grouping-button').click((e) => {
      const $reportControls = $(e.currentTarget).parent();
      const $exampleGroupingControls = $(
        $reportControls.find('.example-grouping-type-controls .field')[0]
      );
      const $groupingFilterContainer = $(
        $reportControls.find('.grouping-reject-container')[0]
      );
      const $clonedControls = this.copyControls(
        $exampleGroupingControls,
        `groupings_reject[${this.totalInputsOnPage}]`
      );

      // Append filters
      $groupingFilterContainer.append($clonedControls);

      // Display title
      const $rejectGroupingsText = $(
        $reportControls.find('.reject-groupings-text')[0]
      );
      $rejectGroupingsText.removeClass('is-hidden');

      this.toggleSubGroupsSwitch($reportControls);
      this.initSelect2Controls($clonedControls);
      this.incrementTotalInputsOnPage();
      this.initRemoveGroupingButtons();
    });
  }

  initRemoveGroupingButtons() {
    $('.remove-grouping-button').click((e) => {
      const $field = $(e.currentTarget).parent().parent();
      const $filterControls = $field.parent().parent();

      // Remove field
      $field.remove();

      const groupingIncludeContainer = $(
        $filterControls.find('.grouping-include-container')[0]
      );
      if (!groupingIncludeContainer.children().length) {
        // Remove title if no filters in the container
        const $acceptGroupingsText = $(
          $filterControls.find('.accept-groupings-text')[0]
        );
        $acceptGroupingsText.addClass('is-hidden');
      } else if (groupingIncludeContainer.children().length === 1) {
        // Remove the type dropdown if 1 or 0 options
        const acceptGroupingType = $(
          $filterControls.find('.grouping-include-type')[0]
        );
        acceptGroupingType.addClass('is-hidden');
      }

      const groupingRejectContainer = $(
        $filterControls.find('.grouping-reject-container')[0]
      );
      if (!groupingRejectContainer.children().length) {
        // Remove title if no filters in the container
        const $acceptGroupingsText = $(
          $filterControls.find('.reject-groupings-text')[0]
        );
        $acceptGroupingsText.addClass('is-hidden');
      }

      this.toggleSubGroupsSwitch($filterControls);
    });
  }

  copyControls($exampleGroupingControls, key) {
    const $clonedControls = $exampleGroupingControls.clone();
    const $clonedSelect = $(
      $clonedControls.find('[name*="department-type"]')[0]
    );
    $clonedSelect.attr('name', `${key}[department-type]`);

    const $clonedInput = $($clonedControls.find('[name*="department-key"]')[0]);
    $clonedInput.attr('name', `${key}[department-key]`);

    return $clonedControls;
  }

  toggleSubGroupsSwitch($reportControls) {
    const $groupingIncludeContainer = $(
      $reportControls.find('.grouping-include-container')[0]
    );
    const $groupingRejectContainer = $(
      $reportControls.find('.grouping-reject-container')[0]
    );

    if (
      $groupingIncludeContainer.children().length ||
      $groupingRejectContainer.children().length
    ) {
      $($reportControls.find('.grouping-include-sub-groups')[0]).removeClass(
        'is-hidden'
      );
    } else {
      $($reportControls.find('.grouping-include-sub-groups')[0]).addClass(
        'is-hidden'
      );
    }
  }

  initSelect2Controls($clonedControls) {
    // Find department type select without the "js-select2" class
    let $newDepartmentType = $clonedControls.find('[name*="department-type"]');
    // activate select2 and add "initSelect2" class
    $newDepartmentType.select2().on('change.select2', function (_e) {
      let $this = $(this);
      // Department select
      let $newDepartment = $this
        .closest('.field')
        .find('[name*="department-key"]');
      $newDepartment
        .select2({
          sorter: (data) => data.sort((a, b) => a.text.localeCompare(b.text)),
          placeholder: 'Search for a grouping',
          minimumInputLength: 2,
          ajax: {
            url: '/account/api/organisation/departments',
            dataType: 'json',
            delay: 300,
            data: function (params) {
              return {
                organisation_id: window.organisation_id,
                departmentTypeSlug: $this.val(),
                search: params.term
              };
            },
            processResults: function (data) {
              return {
                results: _.map(data.data, function (i) {
                  return {
                    id: i.key,
                    text: [i.key, i.title].filter(Boolean).join(' - ')
                  };
                })
              };
            }
          }
        })
        // clear existing options for another department type
        .val(null)
        .trigger('change')
        // autofocus search input
        .on('select2:open', () => {
          document
            .querySelector('.select2-container--open .select2-search__field')
            .focus();
        });
    });
  }

  /**
   * We don't want to have a groupings conflict, and it doesn't really matter if the inputs are in chronological order
   * so we just bump the total inputs for the key every time
   */
  incrementTotalInputsOnPage() {
    this.totalInputsOnPage++;
  }
}
