export default class Department {
  constructor() {
    this.$editDepartmentModal = document.querySelector(
      '#edit-department-modal'
    );
    this.$moveDepartmentModal = document.querySelector(
      '#move-department-modal'
    );
    this.$deleteDepartmentModal = document.querySelector(
      '#delete-department-modal'
    );
    this.$bulkDeleteDepartmentModal = document.querySelector(
      '#bulk-delete-department-modal'
    );

    this.initButtons();
    this.initMoveSelect();
    this.initDeleteRadio();
  }

  initButtons() {
    document
      .querySelectorAll('.department-edit-modal-button')
      .forEach((elem) => {
        elem.addEventListener('click', (e) => this.openEditModal(e));
      });
    document
      .querySelectorAll('.department-move-modal-button')
      .forEach((elem) => {
        elem.addEventListener('click', (e) => this.openMoveModal(e));
      });
    document
      .querySelectorAll('.department-delete-modal-button')
      .forEach((elem) => {
        elem.addEventListener('click', (e) => this.openDeleteModal(e));
      });
    document
      .querySelectorAll('.department-bulk-delete-modal-button')
      .forEach((elem) => {
        elem.addEventListener('click', (e) => this.openBulkDeleteModal(e));
      });
  }

  initMoveSelect() {
    // Not ideal using jquery, but select2 relies on jQuery.
    // So whilst we have select2, we will also have jQuery 🤷🏽
    $('.select2').on('select2:selecting', function (e) {
      let moveConfirmHelperTo = document.querySelector(
        '#move-confirm-helper-to'
      );
      if (moveConfirmHelperTo) {
        moveConfirmHelperTo.textContent = e.params.args.data.id;
      }
    });
  }

  initDeleteRadio() {
    document.querySelectorAll('.delete-type-radio').forEach((elem) => {
      elem.addEventListener('click', (e) => {
        document.querySelector('#delete-type-helper-text').textContent =
          e.currentTarget.value;

        if (e.currentTarget.value === 'hard') {
          document.querySelector('#delete-hard-helper-text').textContent =
            ' and segments';
        } else {
          document.querySelector('#delete-hard-helper-text').textContent = '';
        }
      });
    });
  }

  /**
   * @param {Event} e
   */
  openMoveModal(e) {
    const fromKey = e.currentTarget.dataset.fromDepartment;
    this.$moveDepartmentModal.querySelector('#from-department').value = fromKey;
    this.$moveDepartmentModal.querySelector(
      '#move-confirm-helper-from'
    ).textContent = fromKey;
    this.$moveDepartmentModal.classList.add('is-active');
  }

  /**
   * @param {MouseEvent<HTMLButtonElement, MouseEvent>} e
   */
  openEditModal(e) {
    const departmentTypeSlug = e.currentTarget.dataset.departmentTypeSlug;
    const departmentKey = e.currentTarget.dataset.departmentKey;
    const url =
      e.currentTarget.dataset.meetingUrl ||
      `${window.location.pathname}/${departmentTypeSlug}/${departmentKey}`;

    window.axios.get(url, { withCredentials: true }).then((resp) => {
      this.$editDepartmentModal.querySelector('.modal-content').innerHTML =
        resp.data;
      this.$editDepartmentModal.classList.add('is-active');
    });
  }

  /**
   * @param {Event} e
   */
  openDeleteModal(e) {
    const departmentId = e.currentTarget.dataset.departmentId;
    const departmentKey = e.currentTarget.dataset.departmentKey;

    this.$deleteDepartmentModal.querySelector('#delete-department-id').value =
      departmentId;
    this.$deleteDepartmentModal.querySelector(
      '#delete-key-helper-text'
    ).textContent = departmentKey;
    this.$deleteDepartmentModal.querySelector('#confirm-code-delete').value =
      '';
    this.$deleteDepartmentModal.classList.add('is-active');
  }

  /**
   * @param {Event} e
   */
  openBulkDeleteModal(_e) {
    const listContainer = this.$bulkDeleteDepartmentModal.querySelector(
      '#bulk-delete-list-container'
    );
    listContainer.innerHTML = '';
    let counter = 0;

    document
      .querySelectorAll('.bulk-action-checkbox:checked')
      .forEach((elem) => {
        const key = elem.dataset.departmentKey;

        const row = document.createElement('tr');
        const cell = document.createElement('td');
        cell.innerText = key;
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', `to-delete[]`);
        input.setAttribute('value', key);

        cell.appendChild(input);
        row.appendChild(cell);
        listContainer.appendChild(row);
        counter++;
      });

    if (!counter) {
      window.alert('You must select some rows to bulk delete.');
      return;
    }

    this.$bulkDeleteDepartmentModal.querySelector(
      '#bulk-delete-helper-text'
    ).textContent = counter;
    this.$bulkDeleteDepartmentModal.classList.add('is-active');
  }
}
