import Segment from './calculator/segment';
import Department from "./settings/department";
import Report from "./calculator/report";

require('./bootstrap');
var moment = require('moment');
window.moment = moment;
const bulmaQuickview = require('bulma-extensions/bulma-quickview/dist/js/bulma-quickview.min.js');
var quickviews = bulmaQuickview.attach(); // quickviews now contains an array of all Quickview instances

// Modal controls
$('.control-modal-open').click((e) => {
    $(`#${e.currentTarget.dataset.modalId}`).addClass('is-active');
});

$('.modal-background, .modal-close, .button-close-modal').click(function (e) {
    $('.modal').removeClass('is-active');
});


// Check for click events on the navbar burger icon
$(".navbar-burger").click(function() {
    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    $(".navbar-burger").toggleClass("is-active");
    $(".navbar-menu").toggleClass("is-active");

});

// Tabs click
$('.tabs li[data-target]').click(function(){
    const $tab = $(this.dataset.target);
    const $container = $tab.closest('.tabs-container');
    $container.find('.tabs-content >').addClass('is-hidden');
    $tab.removeClass('is-hidden');
    $container.find('ul li').removeClass('is-active');
    $container.find(`ul li[data-target="${this.dataset.target}"]`).addClass('is-active');
});

$(document).on('keyup', function(e) {
    if (e.key === "Escape") {
        // Close quickviews
        $('.quickview.is-active').removeClass('is-active');
    }
});

const changeQueryStringAndReload = function(key, value) {
    let search_params = new URLSearchParams(window.location.search);
    search_params.set(key, value);
    window.location.search = search_params.toString();
};

// Setup thrust window logic
window.thrust = {
    calculator: {
        segment: new Segment(),
        report: new Report(),
    },
    settings: {
        department: new Department(),
    },
    helpers: {
        changeQueryStringAndReload: changeQueryStringAndReload
    }
};
